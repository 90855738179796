import React from 'react';
import { graphql } from 'gatsby';
import DefaultLayout from '../layouts/Default';
import NewsArticlePage from '../components/NewsArticlePage/statements';

const StatementPage = ({ data }) => {
	const {
		title,
		subHeading,
		downloadCopy,
		contentNode,
		slug,
		versionDate,
		statementSignOffNode,
		statementSignOff,
	} = data.datoCmsSlaveryTraffickingStatement;

	return (
		<NewsArticlePage
			parentBreadcrumb={false}
			articleImageUrl=""
			title={title}
			subHeading={subHeading}
			downloadUrl={downloadCopy.url}
			articleCopy={contentNode}
			slug={slug}
			versionDate={versionDate}
			statementSignOffMdx={statementSignOffNode}
			statementSignOff={statementSignOff}
		/>
	);
};

export const query = graphql`
	query slaveryTraffickingStatment {
		datoCmsSlaveryTraffickingStatement {
			title
			slug
			contentNode {
				childMdx {
					body
				}
			}
			statementSignOff
			statementSignOffNode {
				childMdx {
					body
				}
			}
			downloadCopy {
				url
			}
			versionDate
			seo {
				title
				description
				image {
					gatsbyImageData(
						layout: CONSTRAINED
						width: 450
						imgixParams: { auto: "compress" }
					)
				}
			}
		}
	}
`;

const WrappedStatementPage = (props) => (
	<DefaultLayout fullWidth>
		<StatementPage {...props} />
	</DefaultLayout>
);

export default WrappedStatementPage;
